import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import './AboutUs.css';
import ERAM from "../../ERAM-CAPITAL-ADVISORS.jpg";
import loc from '../../location.png';
import mail from '../../mail.png';
import { Container, Row, Col, Button,Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import {FiMail} from 'react-icons/fi';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export const AboutUs = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showSector, setShowSector] = useState(false);

  const showSectorDropdown = (e)=>{
    setShowSector(!showSector);
    }
    const hideSectorDropdown = e => {
        setShowSector(false);
    }

  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }

  const [expanded, setExpanded] = useState(false);
  return (
    <>
    <div className='about-us-main' id='about-us-main-id'>
    <h2 className='about-us-title'>About us</h2>
    <p className='about-us-details'>Eram Capital Advisors is a special situations investment company focusing on bringing unique investment opportunities to global institutional and family-office co-investors in United Kingdom, Europe and North America. We believe in creating lasting value for our investors through prudent investment of their capital. We invest across various sectors from private equity, real estate to energy and yield superior benefits for our clients.</p>
    </div>
    <Container className="footer__main" id='footer-main-id'>
      <Row className="footer__sub">
        <Col md={5} className="footer__first">
          <img src={ERAM} alt="logo" className="footer__image"></img>

          <div className="footer__add">
            <div className="footer__icon" style={{ color: '#ffffff' }}>
              {/* <i
                className="fas fa-map-marked-alt"
                style={{ color: "#80c2fa" }}
              ></i> */}
            <img src={loc} alt='location' className='footer-location-icon-img' />

            </div>
            <div>
              Kemp House,
              <br /> 160 City Road, London,
              <br /> United Kingdom, EC1V 2NX
            </div>
          </div>
          <div className="footer__add">
            <div className="footer__icon">
              {/* <i className="fas fa-envelope" style={{ color: "#80c2fa" }}></i> */}
              <img src={mail} alt='location' className='footer-location-icon-img' />
            </div>
            <div>info@eramcapital.com</div>
          </div>
          <div className="footer__copy">
            Eram Capital Advisors © 2021. All rights reserved.
          </div>
        </Col>

        <Col className='about-us-links'>
        <p className="navbar-tabs-btn" onClick={()=>{history.push('/firm')}}>Our Firm</p>
        
        <div style={{ marginBottom:'1rem' }}>
        <NavDropdown title="Services" 
            id="collasible-nav-dropdown" 
            show={show}
            onMouseEnter={showDropdown} 
            onMouseLeave={hideDropdown}
            ><NavDropdown.Item className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/investment"
            >
              Investments & Asset Management
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/principal"
            >
              Principal Investments
            </NavLink>
          </NavDropdown.Item>
            </NavDropdown>
            </div>


          <p style={{ marginBottom:'1rem' }}>
            <NavDropdown title="Sector Focus" 
            id="collasible-nav-dropdown" 
            show={showSector}
            onMouseEnter={showSectorDropdown} 
            onMouseLeave={hideSectorDropdown}
            style={{color:'white'}}
            ><NavDropdown.Item className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/agriculture"
            >
              Agriculture
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/energy"
            >
              Energy
            </NavLink>
          </NavDropdown.Item>


          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/Real-Estate"
            >
              Real Estate
            </NavLink>
          </NavDropdown.Item>

          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/infrastructure"
            >
              Infrastructure
            </NavLink>
          </NavDropdown.Item>

          
            </NavDropdown>
            </p>

          <p className="navbar-tabs-btn" onClick={()=>{history.push('/strategy')}}>Strategy</p>
          <p className="navbar-tabs-btn" onClick={()=>{history.push('/contact')}}>Contact Us</p>
        </Col>

        <Col className='about-us-subscribe'>
          <h5 className='subscribe-label'>Subscribe</h5>
          <Form className='about-us-input'>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Subscribe
            </Button>
          </Form>
        </Col>

        
      </Row>
      
      <Row >
        <div className='about-us-more-div'>
        <p>© 2022 All Rights Reserved by Kylo Apps</p>
        <div className='about-us-more'>
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
          <p>Sales and Refunds</p>
          <p>Legal</p>
          <p>Site Map</p>
        </div>
        </div>
      </Row>
    </Container>
    </>
  )
}
