import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import agriculture from "../agriculture.jpg";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import "./Agriculture.css";

export default function Agriculture() {
  return (
    <div className="strategy-main">
    <div className="agriculture-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Agriculture</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Special Situations Investment Company</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital Advisors collaborates with small to medium size private and public companies operating in the agricultural sector in United Kingdom, Europe and North America. Eram develops opportunities for partnership and investment with companies who have expertise and growth prospects in the following industries:
      </p>

    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Dairy and milk products</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Industrial grains: wheat, barley, etc</p>
      </div>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Poultry, beef, and fish farm operations</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Forestry operations</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Agrochemicals, equipment, and logistics</p>
      </div>
    </div>

    <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram believes in the global trend toward greater investment in the agricultural sector for financial and strategic reasons. Eram and our co-investor partners believe in the global demographic factors stimulating this growth, governed largely by:</p>
      </div>
        <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">		Increased energy and water resource demand for non-agricultural activities which is restraining supply in the agricultural sector.</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">			Decrease in land available for certain large-scale agricultural activities due to population growth, land prices for real estate development, and government restrictions.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Increase in food and basic materials consumption driven by global economic growth and global population growth in developed and developing countries.</p>
        </div>
       </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors has unique access to strategic and financial co-investors globally who have an abundance of capital resources and understand the global demographic trends driving the agricultural sector. Eram and its co-investor partners have a long history of agricultural sector experience in key geographies around the world.
Eram and its co-investor partners can provide development capital, growth capital for companies and projects for foreign expansion and can facilitate commercial partnerships in the MENA region.
    </p>
    </div>
    </div>



    // <div>
    //   <div className="agriculture__left">
    //     <h1>Agriculture</h1>
    //   </div>

    //   <Container className="agriculture__right">
    //     <Row>
    //       <Col lg={8} className="agriculture__par">
    //         <p>
    //           Eram Capital partners with small to medium size private and public
    //           companies operating in the agricultural sector in United Kingdom,
    //           Europe and North America. Eram develops opportunities for
    //           partnership and investment with companies who have expertise and
    //           growth prospects in the following industries:
    //         </p>

    //         <div className="agriculture__box">
    //           <ul>
    //             <li>Dairy and milk products</li>
    //             <li>Industrial grains: wheat, barley, etc</li>
    //             <li>Poultry, beef, and fish farm operations</li>
    //             <li>Forestry operations</li>
    //             <li>Agrochemicals, equipment, and logistics</li>
    //           </ul>
    //         </div>
    //         <p>
    //           Eram believes in the global trend toward greater investment in the
    //           agricultural sector for financial and strategic reasons. Our
    //           investor partners believe in the global demographic factors
    //           stimulating this growth, governed largely by:
    //         </p>
    //         <div className="agriculture__list">
    //           <ul>
    //             <li>
    //               Increased energy and water resource demand for
    //               non-agricultural activities which is restraining supply in the
    //               agricultural sector.
    //             </li>
    //             <li>
    //               Decrease in land available for certain large-scale
    //               agricultural activities due to population growth, land prices
    //               for real estate development, and government restrictions.
    //             </li>
    //             <li>
    //               Increase in food and basic materials consumption driven by
    //               global economic growth and global population growth in
    //               developed and developing countries.
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col lg={4} className="agriculture__img">
    //         <img src={agriculture} alt=""></img>
    //       </Col>
    //     </Row>

    //     <div className="agriculture__bottom">
    //       <p>
    //         Eram Capital has unique access to strategic and financial investors
    //         globally who have an abundance of capital resources and understand
    //         the global demographic trends driving the agricultural sector. Eram
    //         and its investor partners have a long history of agricultural sector
    //         experience in key geographies around the world.
    //         <br />
    //         Eram and its investor partners can provide development capital,
    //         growth capital for companies and projects for foreign expansion and
    //         can facilitate commercial partnerships in the MENA region.
    //       </p>
    //     </div>
    //   </Container>
    // </div>
  );
}
