import React from "react";
import energy from "../energy.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./Energy.css";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';

function Energy() {
  return (
    <div className="strategy-main">
    <div className="energy-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Energy</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Special Situations Investment Company</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital Advisors collaborates with small to medium size private and public enterprises operating in the Conventional and Renewable energy sectors ((onshore and offshore) in United Kingdom, Europe and North America. Eram develops opportunities for partnership and investment with companies who have expertise and growth prospects in the following industries:
      </p>
    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Energy production:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Gas and coal fired-power plants</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Solar, wind, and biomass power plants</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">			Alternative energy production through chemical processes</p>
      </div>

      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Energy exploration:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Conventional and unconventional gas and oil projects</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Innovative exploration technologies</p>
      </div>

      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Energy storage and logistics:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	New technologies in bulk storage</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Transportation infrastructure</p>
      </div>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Energy downstream:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Petrochemicals</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Derivative fuel oils</p>
      </div>
      </div>

    </div>

    <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">The global economy is undergoing a significant transformation in the trend toward new technologies in Conventional Energy and the rapid adoption of Renewable Energy production techniques. Eram Capital Advisors and our investor partners believe in the profound demographic factors driving growth in the global Energy sector. Some of the key factors are:</p>
      </div>
        <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Rapid population growth in the developing world putting pressure on energy resources</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">		Increased global productivity in the developed world demanding greater energy production efficiency and longevity</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Governmental policies restricting conventional production and promoting unconventional and renewable production</p>
        </div>
       </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors has unique access to strategic and financial co-investors globally who have an abundance of capital resources and understand the global demographic trends driving the Conventional and Renewable Energy sectors. Eram Capital Advisors and its co-investor partners have a long history of energy sector experience in key geographies around the world.
        Eram Capital Advisors and its investor partners can provide development capital, growth capital for companies and projects for foreign expansion and can facilitate commercial partnerships globally.
    </p>
    </div>

  </div>
    // <div>
    //   <div className="energy__left">
    //     <h1>Energy</h1>
    //   </div>

    //   <Container className="energy__right">
    //     <Row>
    //       <Col lg={8} className="energy__par">
    //         <p>
    //           Eram Capital partners with small to medium size private and public
    //           enterprises operating in the Conventional and Renewable energy
    //           sectors in United Kingdom, Europe and North America. Eram develops
    //           opportunities for partnership and investment with companies who
    //           have expertise and growth prospects in the following industries:
    //         </p>

    //         <div className="energy__box">
    //           <ul>
    //             <li>
    //               Energy production:
    //               <ul>
    //                 <li>Gas and coal fired-power plants</li>
    //                 <li>Solar, wind, and biomass power plants</li>
    //                 <li>
    //                   Alternative energy production through chemical processes
    //                 </li>
    //               </ul>
    //             </li>
    //             <li>
    //               Energy exploration:
    //               <ul>
    //                 <li>
    //                   Conventional and unconventional gas and oil projects
    //                 </li>
    //                 <li>Innovative exploration technologies</li>
    //               </ul>
    //             </li>
    //             <li>
    //               Energy storage and logistics:
    //               <ul>
    //                 <li>New technologies in bulk storage</li>
    //                 <li>Transportation infrastructure</li>
    //               </ul>
    //             </li>
    //             <li>
    //               Energy downstream:
    //               <ul>
    //                 <li>Petrochemicals</li>
    //                 <li>Derivative fuel oils</li>
    //               </ul>
    //             </li>
    //           </ul>
    //         </div>
    //         <p>
    //           The global economy is undergoing a significant transformation in
    //           the trend toward new technologies in Conventional Energy and the
    //           rapid adoption of Renewable Energy production techniques. Eram
    //           Capital and our investor partners believe in the profound
    //           demographic factors driving growth in the global Energy sector.
    //           Some of the key factors are:
    //         </p>
    //         <div className="energy__list">
    //           <ul>
    //             <li>
    //               Rapid population growth in the developing world putting
    //               pressure on energy resources
    //             </li>
    //             <li>
    //               Increased global productivity in the developed world demanding
    //               greater energy production efficiency and longevity
    //             </li>
    //             <li>
    //               Governmental policies restricting conventional production and
    //               promoting unconventional and renewable production
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>

    //       <Col lg={4} className="energy__img">
    //         <img src={energy} alt=""></img>
    //       </Col>
    //     </Row>

    //     <div className="energy__bottom">
    //       <p>
    //         Eram Capital has unique access to strategic and financial investors
    //         globally who have an abundance of capital resources and understand
    //         the global demographic trends driving the Conventional and Renewable
    //         Energy sectors. Eram Capital and its investor partners have a long
    //         history of energy sector experience in key geographies around the
    //         world.
    //         <br />
    //         Eram Capital and its investor partners can provide development
    //         capital, growth capital for companies and projects for foreign
    //         expansion and can facilitate commercial partnerships globally.
    //       </p>
    //     </div>
    //   </Container>
    // </div>
  );
}

export default Energy;
