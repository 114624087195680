import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ERAM from "../../ERAM-CAPITAL-ADVISORS-CROPED.jpg";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import {screenWidth} from '../../constant';
import { Button } from "react-bootstrap";
import {HiMenu} from "react-icons/hi";
import {MdClose} from "react-icons/md";


function NavbaR() {
  var screen = window.innerWidth;
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showSector, setShowSector] = useState(false);

  const showSectorDropdown = (e)=>{
    setShowSector(!showSector);
    }
    const hideSectorDropdown = e => {
        setShowSector(false);
    }

  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }

  const [expanded, setExpanded] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <>
    {
      screen < screenWidth ? (
        <div className="mobile-navbar-main">
          <img
            src={ERAM}
            alt="clickonik"
            className="navbar-logo-img"
            style={{ maxWidth: 128 }}
            onClick={() => {
             history.push("/");
            }}
          />

          <Button onClick={() => setNavbarOpen(!navbarOpen)} style={{background:'var(--primary-color)'}}>
           <HiMenu/>
          </Button>
        </div>
      ):(
    <div className="navbar-main">
        <img src={ERAM} alt ='logo' className="eram-logo-img" onClick={()=>{history.push('/')}}/>
        <div className="navbar-tabs">
            <p className="navbar-tabs-btn" onClick={()=>{history.push('/firm')}}>Our Firm</p>
            <div className="vertical-divider">&nbsp;</div>
            {/* <p className="navbar-tabs-btn">Services</p> */}

            <NavDropdown title="Services" 
            id="collasible-nav-dropdown" 
            show={show}
            onMouseEnter={showDropdown} 
            onMouseLeave={hideDropdown}
            ><NavDropdown.Item className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/investment"
            >
              Investments & Asset Management
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/principal"
            >
              Principal Investments
            </NavLink>
          </NavDropdown.Item>
            </NavDropdown>
            <div className="vertical-divider">&nbsp;</div>
            <NavDropdown title="Sector Focus" 
            bsClass = "custom-class-dropdown"
            id="collasible-nav-dropdown" 
            show={showSector}
            onMouseEnter={showSectorDropdown} 
            onMouseLeave={hideSectorDropdown}
            style={{color:'white'}}
            ><NavDropdown.Item className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/agriculture"
            >
              Agriculture
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/energy"
            >
              Energy
            </NavLink>
          </NavDropdown.Item>


          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/Real-Estate"
            >
              Real Estate
            </NavLink>
          </NavDropdown.Item>

          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/infrastructure"
            >
              Infrastructure
            </NavLink>
          </NavDropdown.Item>

          
            </NavDropdown>
            <div className="vertical-divider">&nbsp;</div>
            <p className="navbar-tabs-btn" onClick={()=>{history.push('/strategy')}}>Strategy</p>
            <div className="vertical-divider">&nbsp;</div>
            <p className="navbar-tabs-btn" onClick={()=>{history.push('/contact')}}>Contact Us</p>
        </div>
    </div>
      )}
            {navbarOpen && (
        <div className="drawer-container-main">
          <div className="mobile-navbar-main">
            <img src={ERAM} alt="sos-icon" style={{ maxWidth: 128 }} />
            <Button
              className="drawer-closeBtn"
              onClick={() => setNavbarOpen(false)}
              style={{background:'var(--primary-color)'}}
            >
              <MdClose />
            </Button>
          </div>
          <div className="drawer-content">
            <div className="menuItem-div">
              <NavLink
                className="menuContent-item"
                to="/firm"
                exact
                onClick={() => setNavbarOpen(false)}
                activeClassName="active-menuItem"
              >
                Our Firm
              </NavLink>
            
              <div className="services-dropdown">
              <NavDropdown title="Services" 
            id="collasible-nav-dropdown" 
            show={show}
            onMouseEnter={showDropdown} 
            onMouseLeave={hideDropdown}
            ><NavDropdown.Item className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/investment"
            >
              Investments & Asset Management
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/principal"
            >
              Principal Investments
            </NavLink>
          </NavDropdown.Item>
            </NavDropdown>
            </div>

          
          <div style={{borderBottom: '1px solid #76939f', width:'100%', height:'1px'}}>&nbsp;</div>

            <div className="services-dropdown">
            <NavDropdown title="Sector Focus" 
            bsClass = "custom-class-dropdown"
            id="collasible-nav-dropdown" 
            show={showSector}
            onMouseEnter={showSectorDropdown} 
            onMouseLeave={hideSectorDropdown}
            style={{color:'white'}}
            ><NavDropdown.Item className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/agriculture"
            >
              Agriculture
            </NavLink>
          </NavDropdown.Item>
          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/energy"
            >
              Energy
            </NavLink>
          </NavDropdown.Item>


          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/Real-Estate"
            >
              Real Estate
            </NavLink>
          </NavDropdown.Item>

          <NavDropdown.Item href="" className="drop__item">
            <NavLink
              onClick={() => setExpanded(false)}
              className="nav-link"
              activeClassName="active"
              to="/infrastructure"
            >
              Infrastructure
            </NavLink>
          </NavDropdown.Item>
          </NavDropdown>
            </div>

            <div style={{borderBottom: '1px solid #76939f', width:'100%', height:'1px'}}>&nbsp;</div>



              <NavLink
                className="menuContent-item"
                to="/strategy"
                exact
                onClick={() => setNavbarOpen(false)}
                activeClassName="active-menuItem"
              >
                Strategy
              </NavLink>

              <NavLink
                className="menuContent-item"
                to="/contact"
                exact
                onClick={() => setNavbarOpen(false)}
                activeClassName="active-menuItem"
              >
                Contact Us
              </NavLink>

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavbaR;
