import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Button, Modal } from "react-bootstrap";
import "./Cookie.css";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Manage Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "400px", overflowY: "scroll" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5>Strictly necessary cookies</h5>
          <input type="checkbox" checked />
        </div>
        <p>
          These cookies are necessary for the Rightmove website to function.
          Examples of this include: setting your privacy preferences, logging in
          to your Rightmove account, or filling in forms. Though these cookies
          cannot be switched off, you can set your browser to block or alert you
          about these cookies, but please be aware that this will stop some
          parts of the Rightmove website from functioning as intended.
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5>Functional cookies</h5>
          <input type="checkbox" />
        </div>
        <p>
          These cookies allow us to understand how people use our website and
          where they came from, so that we can measure and improve the
          performance of our website, as well as to remember your preferences.
          They help us see which pages are the most and least popular, and how
          visitors move around the Rightmove website. These cookies also allow
          us to give you tools like videos and currency converters.
          <br /> When we use the information from these cookies it is grouped
          together and therefore anonymous. If you do not allow these cookies we
          will not know when you have visited our website and will not be able
          to monitor its performance.
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5>Targeting and tracking</h5>
          <input type="checkbox" />
        </div>
        <p>
          These cookies record your visit to the Rightmove website, the
          individual pages you visit, the links you follow and the type of
          device you use. We will use this information to make the Rightmove
          website and the adverts we show you more relevant to your interests.
          Our use of these cookies might also mean that you may see Rightmove
          adverts when you visit Facebook.
          <br /> These cookies record your activity on the Rightmove website and
          our partners may use that information to show you adverts that they
          think you will be most interested in when you visit other websites.
          Our partners may also use information recorded by these cookies to see
          how well their adverts are performing.
          <br /> If you do not allow these cookies, you may see more content and
          adverts that do not match your interests.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.accept}>Save & Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Cookie() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <CookieConsent
        className="app__cookie"
        location="bottom"
        buttonText="Allow All cookies"
        cookieName="myAwesomeCookie"
        style={{
          background: "#3d56b2",
          display: "flex",
          alignItems: "center",
        }}
        expires={30}
        buttonClasses="btn btn-primary"
        contentClasses="text-capitalize"
        hideOnAccept={true}
      >
        <div
         className="cookie__content"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "13px" }}>
            We use cookies to help improve your experience with personalised
            content and tailored advertisements. You can control these by
            clicking 'Manage settings'.
          </div>
          <button className="app__button" onClick={() => setModalShow(true)}>
            Manage Settings
          </button>
        </div>
      </CookieConsent>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        accept={() => {
          document.getElementById("rcc-confirm-button").click();
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default Cookie;
