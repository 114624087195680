import React from "react";
import strategy1 from "../strategy1.jpg";
import strategy2 from "../strategy2.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./Strategy.css";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import './Estate.css';

function Estate() {
  return (
    <div className="strategy-main">
      <div className="estate-top-div">
        <div><NavbaR/></div>
        <h1 className="strategy-label">Real Estate</h1>
      </div>

      <div className="strategy-evaluation-main">
        <h3 className="strategy-evaluation-title">Special Situations Investment Company</h3>
        <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
        <p className="evaluation-details">Eram Capital Advisors collaborates (to avoid partner word) with medium to large size private and public companies operating in the residential and commercial real estate sectors in United Kingdom, Europe and North America, with a focus on Capital or large Commercial Cities. Eram Capital originates, develops opportunities and formulates partnerships with real estate developers and co-investors who wish to access and deploy strategic capital. Eram Capital and its investors focus on the following areas:
        </p>

      </div>

      <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Residential, logistics, student housing, and commercial investments</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Pre- and post- construction development financing structures</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">High-income oriented investment opportunities</p>
        </div>
      </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors focuses on real estate projects and companies which are in key urban locations in the geographies we have unique access to and local expert teams on the ground. We and our co-investors believe in the global demographic and economic trends driving growth in the residential and commercial real estate sectors, largely governed by:</p>
      </div>
        <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Business globalization and the concentration in key locations</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Urbanization and general population growth</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Global growth in products and services logistics operations</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Increase in global capital allocation to the real estate asset class</p>
        </div>
      </div>

      
      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors has unique access to strategic and financial co-investors globally who have an abundance of capital resources and understand the global demographic trends driving real estate markets in key global cities in the UK, Europe, and the USA.
Eram and its co-investor partners have a long history of Real Estate sector experience in key geographies around the world, with a demonstrable track record.
Eram and its co-investor partners can provide development capital, growth capital for real estate projects and can facilitate strategic investing partnerships to attract pipeline opportunities in identified sub-sectors. to build platform investments.
</p>
      </div>
    </div>
  )}



































// import React from "react";
// import estate from "../estate.jpg";
// import { Container, Row, Col } from "react-bootstrap";
// import "./Estate.css";

// function Estate() {
//   return (
//     <div>
//       <div className="estate__left">
//         <h1>Real Estate</h1>
//       </div>

//       <Container className="estate__right">
//         <Row>
//           <Col lg={8} className="estate__par">
//             <p>
//               Eram Capital partners with medium to large size private and public
//               companies operating in the residential and commercial real estate
//               sectors in United Kingdom, Europe and North America. Eram Capital
//               develops opportunities and formulates partnerships with real
//               estate developers and investors who wish to access strategic
//               capital from investors. Eram Capital and its investors focus on
//               the following areas:
//             </p>
//             <div className="estate__box">
//               <ul>
//                 <li>
//                   Residential, logistics, student housing, and commercial
//                   investments
//                 </li>
//                 <li>
//                   Pre- and post- construction development financing structures
//                 </li>
//                 <li>High-income oriented investment opportunities</li>
//               </ul>
//             </div>

//             <p>
//               Eram Capital focuses on real estate projects and companies which
//               are in key urban locations in the geographies we have unique
//               access to. We and our investors believe in the global demographic
//               and economic trends driving growth in the residential and
//               commercial real estate sectors, largely governed by:
//             </p>
//             <div className="estate__list">
//               <ul>
//                 <li>
//                   Business globalization and the concentration in key locations
//                 </li>
//                 <li>Urbanization and general population growth</li>
//                 <li>
//                   Global growth in products and services logistics operations
//                 </li>
//                 <li>
//                   Increase in global capital allocation to the real estate asset
//                   class
//                 </li>
//               </ul>
//             </div>
//           </Col>
//           {/* <Col lg={1}></Col> */}
//           <Col lg={4} className="estate__img">
//             <img src={estate} alt=""></img>
//           </Col>
//         </Row>

//         <div className="estate__bottom">
//           <p>
//             Eram Capital has unique access to strategic and financial investors
//             globally who have an abundance of capital resources and understand
//             the global demographic trends driving real estate markets in key
//             global cities in the UK, Europe, and the USA.
//             <br /> Eram and its investor partners have a long history of Real
//             Estate sector experience in key geographies around the world.
//             <br /> Eram and its investor partners can provide development
//             capital, growth capital for real estate projects and can facilitate
//             strategic investing partnerships to attract pipeline opportunities
//             in identified sub-sectors.
//           </p>
//         </div>
//       </Container>
//     </div>
//   );
// }

export default Estate;
