import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import principal from "../principal.jpg";
import "./Principal.css";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs'

function Principal() {
  return (
    <div className="strategy-main">
    <div className="principal-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Principal Investments</h1>
    </div>
    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Investment Activities</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital deploys its own partner capital toward unique special situations investment opportunities to co-invest alongside our other investment partners or to serve as an anchor investor. Eram specializes in complex, illiquid, high return and long-dated investment structures and utilizes its expertise to efficiently invest in such opportunities. Eram can deploy proprietary capital toward working capital and investment capital requirements at the company, project or asset level.
      </p>

    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Investing proprietary capital alongside investor clients in deals originated by Eram.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Facilitating transactions in the form of working capital for deal due diligence & structuring or investment capital in the underlying asset or company.</p>
      </div>
    </div>

  </div>



    // <div>
    //   <div className="principal__left">
    //     <h1>Principal Investments</h1>
    //   </div>

    //   <Container className="principal__outer">
    //     <Row className="principal__right">
    //       <Col lg={8}>
    //         <p className="principal__para">
    //           Eram Capital deploys its own partner capital toward unique special
    //           situations investment opportunities to co-invest alongside our
    //           other investment partners or to serve as an anchor investor. Eram
    //           specializes in complex, illiquid, high return and long-dated
    //           investment structures and utilizes its expertise to efficiently
    //           invest in such opportunities. Eram can deploy proprietary capital
    //           toward working capital and investment capital requirements at the
    //           company, project or asset level.
    //         </p>
    //         <h2 className="principal__heading">Investment Activities</h2>
    //         <div className="principal__list">
    //           <ul>
    //             <li>
    //               Investing proprietary capital alongside investor clients in
    //               deals originated by Eram.
    //             </li>
    //             <li>
    //               Facilitating transactions in the form of working capital for
    //               deal due diligence & structuring or investment capital in the
    //               underlying asset or company.
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col lg={4} className="principal__img">
    //         <img src={principal} alt="principal"></img>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
}

export default Principal;
