import React from "react";
import homeImg from "../../tagline.jpg";
import arrow from "../../arrow.png";
import "./Tagline1.css";
import { BsArrowRight } from "react-icons/bs";

const Tagline1 = () => {
  return (
    <div className="tagline-main">
      <div className="img-container">
        <img src={homeImg} alt="home-img" className="tagline-img" />
        <h5 className="tagline-img-text">ERAM CAPITAL ADVISORS</h5>
      </div>
      <div className="tagline-content">
        <h3 className="tagline-content-heading">
          Special Situations Investment Company
        </h3>
        <p className="tagline-content-details">
          We believe in creating lasting value for our investors through prudent
          investment of their capital. We invest across various sectors from
          private equity, real estate to energy and yield superior benefits for
          our clients.
        </p>
        <a href="#about-us-main-id" style={{ textDecoration: "none" }}>
          <div className="tagline-know-more">
            <p className="home-top-know-more">
              {" "}
              Know more <BsArrowRight />
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Tagline1;
