import React from "react";
import { useHistory } from "react-router-dom";
import realEstate from '../estate1.png';
import infra from '../infra1.png';
import agri from '../agriculture1.png';
import energy from '../energy1.png';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Firm.css";
import About from "./About";
import Vision from "./Vision";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';
import {BsArrowRight} from 'react-icons/bs';

function Firm() {
  const history = useHistory();

  return (
    <div className="strategy-main">
    <div className="firm-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Our Firm</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">The Importance of Defining Your Investment Strategy</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital is a special situations investment company focusing on bringing unique investment opportunities to global institutional and family-office co-investors in United Kingdom, Europe and North America.
        We believe in creating lasting value for our investors through prudent investment of their capital. We invest across various sectors from private equity, real estate to energy and yield superior benefits for our clients.
      </p>
    </div>

    <div className='sector-cards' id='sector-cards-id'>
            {data?.map((card)=>(
                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${card.title.split(' ').join('-')}`)}}>
                    <img src ={card.imgUrl} alt='img' className='sector-card-img' id='sector-card-img-id' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{card.id}</h3>
                        <h4 className='sector-card-title'>{card.title}</h4>
                    </div>
                    
                </div>
            ))

            }
        </div>

    {/* <div className='sector-cards' id='sector-cards-id'>
                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[0].title.split(' ').join('-')}`)}}>
                    <img src ={data[0].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[0].id}</h3>
                        <h4 className='sector-card-title'>{data[0].title}</h4>
                    </div>
                    
                </div>

                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[1].title.split(' ').join('-')}`)}}>
                    <img src ={data[1].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[1].id}</h3>
                        <h4 className='sector-card-title'>{data[1].title}</h4>
                    </div>
                </div>            
        </div>

        <div className='sector-cards' id='sector-cards-id'>
                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[2].title.split(' ').join('-')}`)}}>
                    <img src ={data[2].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[2].id}</h3>
                        <h4 className='sector-card-title'>{data[2].title}</h4>
                    </div>
                    
                </div>

                <div className='sector-card-main' id='sector-card-main-id' onClick={()=>{ history.push(`/${data[3].title.split(' ').join('-')}`)}}>
                    <img src ={data[3].imgUrl} alt='img' className='sector-card-img' />
                    <div className='sector-card-know-more'>
                    <p className="home-top-know-more"> Know more   <BsArrowRight/></p>
                    </div>
                    <div className='sector-card-detail'>
                        <h3 className='sector-card-id'>{data[3].id}</h3>
                        <h4 className='sector-card-title'>{data[3].title}</h4>
                    </div>
                </div>            
        </div> */}






    
    </div>

  );
}

export default Firm;

const data = [
  {
      imgUrl : realEstate,
      id : '01',
      title : 'Real Estate'
  },
  {
      imgUrl : infra,
      id : '02',
      title : 'Infrastructure'
  },
  {
      imgUrl : agri,
      id : '03',
      title : 'Agriculture'
  },
  {
      imgUrl : energy,
      id : '04',
      title : 'Energy'
  },

]