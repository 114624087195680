import React from "react";
import infrastructure from "../infrastructure1.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./Infrastructure.css";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs';

function Infrastructure() {
  return (
    <div className="strategy-main">
    <div className="infra-top-div">
      <div><NavbaR/></div>
      <h1 className="strategy-label">Infrastructure</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Special Situations Investment Company</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital Advisors collaborates with medium to large size private and public companies operating in industrial infrastructure in United Kingdom, Europe and North America. Eram Capital Advisors develops opportunities and formulates partnerships with operator companies or assets who have expertise and growth prospects in the key industries. Eram Capital Advisors and its investors focus on the following areas:
      </p>

    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Airports</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Shipping</p>
      </div>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Bridges, dams, roads</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Water treatments</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Power Generation & Transmission</p>
      </div>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Communication networks</p>
      </div>
    </div>

    <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">The global economy is undergoing a significant transformation in the trend toward creating new infrastructure for increasing demand and the rapid adoption of manufacturing techniques. Eram Capital Advisors and our co-investor partners believe in the profound demographic factors driving growth in the global Infrastructure sector. Some of the key factors are:</p>
      </div>
        <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">		Rapid population growth in the developing world putting pressure on existing infrastructure.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Increased global economic activity in both the developed and developing world demanding new infrastructure resources and efficiency</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Governmental policies facilitating support for infrastructure modernisation and expansion</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Urbanization and general population growth</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Global growth in products and services logistics operations.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Increase in global capital allocation to the infrastructure asset class.</p>
        </div>
      </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors has unique access to strategic and financial investors globally who have an abundance of capital resources and understand the global demographic trends driving the Infrastructure sectors. Eram Capital Advisors and its co-investor partners have a long history of infrastructure sector experience in key geographies around the world. We can provide development capital, growth capital for companies and projects for foreign expansion. We can facilitate strategic and commercial investing partnerships globally to attract pipeline opportunities in identified sub-sectors – especially for platform investments that enable further investment opportunities.
    </p>
    </div>
    </div>
    );
}

export default Infrastructure;

















    // <div>
    //   <div className="inf__left">
    //     <h1>Infrastructure</h1>
    //   </div>

    //   <Container className="inf__page">
    //     <Row>
    //       <Col lg={8}>
    //         <p className="inf__para">
    //           Eram Capital partners with medium to large size private and public
    //           companies operating in industrial infrastructure in United
    //           Kingdom, Europe and North America. Eram Capital develops
    //           opportunities and formulates partnerships with operator companies
    //           or assets who have expertise and growth prospects in the key
    //           industries. Eram Capital and its investors focus on the following
    //           areas:
    //         </p>
    //         <div className="inf__box">
    //           <ul>
    //             <li>Airports</li>
    //             <li>Shipping</li>
    //             <li>Bridges, dams, roads</li>
    //             <li>Water treatments</li>
    //             <li>Power Generation & Transmission</li>
    //             <li>Communication networks</li>
    //           </ul>
    //         </div>
    //         <p className="inf__para">
    //           The global economy is undergoing a significant transformation in
    //           the trend toward creating new infrastructure for increasing demand
    //           and the rapid adoption of manufacturing techniques. Eram Capital
    //           and our investor partners believe in the profound demographic
    //           factors driving growth in the global Infrastructure sector. Some
    //           of the key factors are:
    //         </p>
    //         <div className="inf__list">
    //           <ul>
    //             <li>
    //               Rapid population growth in the developing world putting
    //               pressure on existing infrastructure.
    //             </li>
    //             <li>
    //               Increased global economic activity in both the developed and
    //               developing world demanding new infrastructure resources and
    //               efficiency
    //             </li>
    //             <li>
    //               Governmental policies facilitating support for infrastructure
    //               modernisation and expansion
    //             </li>
    //             <li>Urbanization and general population growth.</li>
    //             <li>
    //               Global growth in products and services logistics operations.
    //             </li>
    //             <li>
    //               Increase in global capital allocation to the infrastructure
    //               asset class.
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col lg={4}>
    //         <img
    //           className="inf__img"
    //           src={infrastructure}
    //           alt="infrastructure"
    //         />
    //       </Col>
    //     </Row>

    //     <div className="inf__bottom">
    //       <p>
    //         Eram Capital has unique access to strategic and financial investors
    //         globally who have an abundance of capital resources and understand
    //         the global demographic trends driving the Infrastructure sectors.
    //         Eram Capital and its investor partners have a long history of
    //         infrastructure sector experience in key geographies around the
    //         world. We can provide development capital, growth capital for
    //         companies and projects for foreign expansion. We can facilitate
    //         strategic and commercial investing partnerships globally to attract
    //         pipeline opportunities in identified sub-sectors.
    //       </p>
    //     </div>
    //   </Container>
    // </div>
