import React from "react";
import "./Investment.css";
import { Container, Row, Col } from "react-bootstrap";
import investment from "../investment.jpg";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs'

function Investment() {
  return (
    <div className="strategy-main">
      <div><NavbaR/></div>
    <div className="investment-top-div">
      <h1 className="strategy-label">Investments & Asset Management</h1>
    </div>

    <div className="strategy-evaluation-main">
      <h3 className="strategy-evaluation-title">Investment Activities</h3>
      <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
      <p className="evaluation-details">Eram Capital identifies unique global special situations opportunities sourced through its proprietary channels. Eram focuses on high-return investment in public and private companies in key sectors that are being driven by globally-oriented demographic factors: energy consumption, population growth, urbanization, food consumption and globalization. Eram co-invests alongside its partners to achieve alignment of interests for the long-term. Investments are made directly in the company or project capital structure or via portfolio and managed account structures. Eram Capital manages investments through a variety of risk and performance criteria established with our co-investors with the target of achieving a successful monetization of the investments. Monetizations may include partnering with other co-investors, achieving a partial or complete trade sale or issuance of securities in the capital markets.
      </p>

    </div>

    <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Identification and structuring of investment opportunities</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Structuring of managed accounts and private investment vehicles</p>
      </div>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Allocation and management of investor capital</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Strategic monetization and commercial development of portfolio companies      </p>
      </div>


    </div>

  </div>




    // <div>
    //   <div className="investment__left">
    //     <h1>Investments & Asset Management</h1>
    //   </div>

    //   <Container className="investment__outer">
    //     <Row className="investment__right">
    //       <Col lg={7}>
    //         <p className="investment__para">
    //           Eram Capital identifies unique global special situations
    //           opportunities sourced through its proprietary channels. Eram
    //           focuses on high-return investment in public and private companies
    //           in key sectors that are being driven by globally-oriented
    //           demographic factors: energy consumption, population growth,
    //           urbanization, food consumption and globalization. Eram co-invests
    //           alongside its partners to achieve alignment of interests for the
    //           long-term. Investments are made directly in the company or project
    //           capital structure or via portfolio and managed account structures.
    //           Eram Capital manages investments through a variety of risk and
    //           performance criteria established with our co-investors with the
    //           target of achieving a successful monetization of the investments.
    //           Monetizations may include partnering with other co-investors,
    //           achieving a partial or complete trade sale or issuance of
    //           securities in the capital markets.
    //         </p>
    //         <h2 className="investment__head">Investment Activities</h2>
    //         <div className="investment__list">
    //           <ul>
    //             <li>
    //               Identification and structuring of investment opportunities
    //             </li>
    //             <li>
    //               Structuring of managed accounts and private investment
    //               vehicles
    //             </li>
    //             <li>Allocation and management of investor capital</li>
    //             <li>
    //               Strategic monetization and commercial development of portfolio
    //               companies
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //       <Col lg={5} className="investment__img">
    //         <img src={investment} alt="investment"></img>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
}

export default Investment;
