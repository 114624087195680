import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
// import About from "./Components/About";
import Advisory from "./Components/Advisory";
import Agriculture from "./Components/Agriculture";
import Contact from "./Components/Contact";
import Energy from "./Components/Energy";
import Estate from "./Components/Estate";

import Footer from "./Components/Footer";
// import History from "./Components/History";
import Home from "./Components/Home";
import Investment from "./Components/Investment";
import "./Components/Navbar1";
import Navbar1 from "./Components/Navbar1";
import Principal from "./Components/Principal";

// import Vision from "./Components/Vision";
import Policy from "./Components/Policy";
import ScrollToTop from "./Components/ScrollToTop";
import Infrastructure from "./Components/Infrastructure";
import Strategy from "./Components/Strategy";
import Firm from "./Components/Firm";
import Cookie from "./Cookie";
import About from "./Components/About";
import { AboutUs } from "./Components/About/AboutUs";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/firm" component={Firm}></Route>
          <Route exact path="/advisory" component={Advisory}></Route>
          <Route exact path="/investment" component={Investment}></Route>
          <Route exact path="/principal" component={Principal}></Route>
          <Route exact path="/agriculture" component={Agriculture}></Route>
          <Route exact path="/energy" component={Energy}></Route>
          <Route exact path="/Real-Estate" component={Estate}></Route>
          <Route
            exact
            path="/infrastructure"
            component={Infrastructure}
          ></Route>
          <Route exact path="/strategy" component={Strategy}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/privacy-policy" component={Policy}></Route>
        </Switch>
        <AboutUs/>
      </BrowserRouter>
      <Cookie />
    </div>
  );
}

export default App;
