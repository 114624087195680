import React from "react";
import strategy1 from "../strategy1.jpg";
import strategy2 from "../strategy2.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./Strategy.css";
import NavbaR from "./Navbar/NavbaR";
import {BsDot} from 'react-icons/bs'

function Strategy() {
  return (
    <div className="strategy-main">
      <div className="strategy-top-div">
        <div><NavbaR/></div>
        <h1 className="strategy-label">Strategy</h1>
      </div>

      <div className="strategy-evaluation-main">
        <h3 className="strategy-evaluation-title">Evaluation Criteria</h3>
        <div className="title-placer" style={{width:'16rem'}}>&nbsp;</div>
        <p className="evaluation-details">Eram Capital Advisors and its investor partners focus on companies and projects, either directly or via portfolio investments through sponsors, which can deliver unique return performance with acceptable and manageable levels of risk.
        </p>
      </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors focuses on special situations opportunities defined as those opportunities offering unique investment return potential with atypical risk characteristics. The scope of our screening process is not limited to a narrow definition and may include restructuring opportunities, growth capital situations, complex structure investments, or early stage development projects.
      </p>
      </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors works closely with potential companies and identifies investment opportunities that are synergistic to both the companies and our investor partners. We base this determination on a number of fundamental bottoms-up factors which complement our assessment of the opportunity alongside the strategic factors. Our key evaluation criteria of the company, project, or sponsored portfolio investment are:
      </p>
      </div>



      <div className="evaluation-points-main">
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Management performance, integrity and governance.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Past and projected financial performance.</p>
        </div>
        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Supporting macro economic growth factors.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">Risk / return profile and transparency of the investment structure.</p>
        </div>

        <div className="evaluation-point">
          <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
          <p className="evaluation-point-details">	Liquidity of the investment with emphasis on exit strategies.</p>
        </div>
      </div>

      <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors works closely with potential companies and identifies investment opportunities that are synergistic to both the companies and our investor partners. We base this determination on a number of fundamental bottoms-up factors which complement our assessment of the opportunity alongside the strategic factors. Our key evaluation criteria of the company, project, or sponsored portfolio investment are:
      </p>
      </div>

      <div className="evaluation-points-main">
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Business Strategy:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Clarity and longevity of strategy.</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Feasibility.</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Innovation.</p>
      </div>

      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Complementary fit with our other investments:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Opportunities for the domestic market.</p>
      </div>
      
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Opportunities for the domestic market.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Willingness to develop partnerships</p>
      </div>

      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Growth Capital Expansion:</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">	Marginal impact of capital investment on growth.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Life cycle of business plan.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">Magnitude of capital requirement.</p>
      </div>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Strategic Sector or Geography</p>
      </div>

      <div style={{paddingLeft:'3rem'}}>
      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Growth cycle of sector or region.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Demographic drivers.</p>
      </div>

      <div className="evaluation-point">
        <div className="bullet-point"><BsDot style={{fontSize:'2rem'}}/></div>
        <p className="evaluation-point-details">		Sustainability</p>
      </div>
      </div>

    </div>

    <div style={{padding:'1rem 4rem', textAlign:'left'}}>
        <p className="evaluation-details">Eram Capital Advisors and its investor partners focus on companies and projects, either directly or via portfolio investments through sponsors, that exhibit strong bias toward sustainable growth and feasibility of success over the medium term.
      </p>
    </div>
     

    </div>
    // <div >
    //   <div className="str__left">
    //     <h1>Strategy</h1>
    //   </div>
    //   <Container className="str__page">
    //     <Row>
    //       <Col lg={6}>
    //         <p className="str__para">
    //           Eram Capital identifies investment and partnership opportunities
    //           based on a number of key strategic factors to determine the
    //           suitability for its investor partners as well as for the company
    //           or project sponsor seeking investment. Some of the primary factors
    //           are:
    //         </p>
    //         <div className="str__list">
    //           <ul>
    //             <li>
    //               Business Strategy:
    //               <ul>
    //                 <li>Clarity and longevity of strategy.</li>
    //                 <li>Feasibility.</li>
    //                 <li>Innovation.</li>
    //               </ul>
    //             </li>
    //             <li>
    //               Complementary fit with our other investments:
    //               <ul>
    //                 <li>Opportunities for the domestic market.</li>
    //                 <li>Global sector synergies.</li>
    //                 <li>Willingness to develop partnerships</li>
    //               </ul>
    //             </li>
    //             <li>
    //               Growth Capital Expansion:
    //               <ul>
    //                 <li>Marginal impact of capital investment on growth.</li>
    //                 <li>Life cycle of business plan.</li>
    //                 <li>Magnitude of capital requirement.</li>
    //               </ul>
    //             </li>
    //             <li>
    //               Strategic Sector or Geography:
    //               <ul>
    //                 <li>Growth cycle of sector or region.</li>
    //                 <li>Demographic drivers.</li>
    //                 <li>Sustainability</li>
    //               </ul>
    //             </li>
    //           </ul>
    //         </div>
    //         <p className="str__para">
    //           Eram Capital and its investor partners focus on companies and
    //           projects, either directly or via portfolio investments through
    //           sponsors, that exhibit strong bias toward sustainable growth and
    //           feasibility of success over the medium term.
    //         </p>
    //       </Col>
    //       <Col lg={6} className="str__img">
    //         <img src={strategy1} alt="strategy" />
    //       </Col>
    //     </Row>

    //     <div>
    //       <h2 className="mid__heading">Evaluation Criteria</h2>
    //     </div>

    //     <Row className="str__bott">
    //       <Col lg={6} className="str__bott2">
    //         <img src={strategy2} alt="" />
    //       </Col>
    //       <Col lg={6} className="str__bott1">
    //         <p>
    //           Eram Capital and its investor partners focus on companies and
    //           projects, either directly or via portfolio investments through
    //           sponsors, which can deliver unique return performance with
    //           acceptable and manageable levels of risk.
    //         </p>
    //         <p>
    //           Eram Capital focuses on special situations opportunities defined
    //           as those opportunities offering unique investment return potential
    //           with atypical risk characteristics. The scope of our screening
    //           process is not limited to a narrow definition and may include
    //           restructuring opportunities, growth capital situations, complex
    //           structure investments, or early stage development projects.
    //         </p>
    //         <p>
    //           Eram Capital works closely with potential companies and identifies
    //           investment opportunities that are synergistic to both the
    //           companies and our investor partners. We base this determination on
    //           a number of fundamental bottoms-up factors which complement our
    //           assessment of the opportunity alongside the strategic factors. Our
    //           key evaluation criteria of the company, project, or sponsored
    //           portfolio investment are:
    //         </p>
    //         <div className="str__list1">
    //           <ul>
    //             <li>Management performance, integrity and governance.</li>
    //             <li>Past and projected financial performance.</li>
    //             <li>Supporting macro economic growth factors.</li>
    //             <li>
    //               Risk / return profile and transparency of the investment
    //               structure.
    //             </li>
    //             <li>
    //               Liquidity of the investment with emphasis on exit strategies.
    //             </li>
    //           </ul>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
}

export default Strategy;
